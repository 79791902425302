import React, { FC } from 'react';
import RTLayout from '../../components/global/layout/layout';

const animatedbackground = require('../../assets/images/error/animated-poolparty.webm').default;

const Background: FC<any> = ({ src }: any) => {
  if (typeof window !== 'undefined' && window.innerWidth > 768) {
    return (
      <video className='right-video' autoPlay loop poster={src}>
        <source src={animatedbackground} type='video/webm' />
      </video>
    );
  } else {
    return <img className='right-image' src={src} alt=''></img>;
  }
};

const RTNotFoundPage: FC<{ pageContext: any }> = ({ pageContext }) => {
  return (
    <RTLayout
      menuText={pageContext?.uiElements?.menuTxt[0]}
      footerText={pageContext?.uiElements?.footerTxt[0]}
      langModalTranslations={{ title: pageContext?.uiElements?.languageModalTitle, subtitle: pageContext?.uiElements?.languageModalText }}
      cookie={{
        cookieContent: pageContext?.uiElements.cookieContent,
        cookieButtonAccept: pageContext?.uiElements.cookieButtonAccept,
        cookieButtonReject: pageContext?.uiElements.cookieButtonReject,
      }}
    >
      <div className='error'>
        <div className='error-left'>
          <div
            className='wysiwyg left-text'
            dangerouslySetInnerHTML={{
              __html: pageContext.errorMessage,
            }}
          ></div>
        </div>
        <div className='error-right'>
          <Background src={pageContext.background404[0].url} />
        </div>
      </div>
    </RTLayout>
  );
};

export default RTNotFoundPage;
